import React from "react";
import { FormattedMessage } from "react-intl";

import "./landing.styl";

const Landing = () => (
    <div className="landing">
        <div className="content">
            <div className="catch">
                <div className="fixed">
                    <FormattedMessage id={`pages.home.catch`} />
                </div>
            </div>
        </div>
    </div>
);

export default Landing;

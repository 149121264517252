import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";

import "./slider.styl";
// import Carousel from "../../components/Carousel";
import Carousel from "../../components/Slider";

const Slider = ({ items }) => {
    return (
        <div className="slider-bloc">
            <div className="container">
                <h3>
                    <FormattedMessage id="actu.title" />
                </h3>
                <Carousel items={items} />
            </div>
        </div>
    );
};

Slider.propTypes = {
    items: PropTypes.array.isRequired
};

export default Slider;

/* eslint-disable react/prop-types */
import React from 'react';
import Slider from 'react-slick';
import PropTypes from 'prop-types';
import Img from 'gatsby-image/withIEPolyfill';
import { injectIntl, FormattedMessage } from 'react-intl';
import IconButton from '@material-ui/core/IconButton';
import { withStyles } from '@material-ui/styles';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';

import './slick.styl';
import { altify } from '../../../utils/strings';

const CustomButton = withStyles(() => ({
    root: {
        position: 'absolute',
        color: 'white',
        '&:before': {
            display: 'none',
        },
        '&:hover,&:focus': {
            color: 'white',
        },
    },
    colorSecondary: {
        backgroundColor: 'rgba(255, 255, 255, .08)',
        '&:hover': {
            backgroundColor: 'rgba(255, 255, 255, .2)',
        },
        '&:focus': {
            backgroundColor: 'rgba(255, 255, 255, .2)',
        },
    },
}))(IconButton);

const PrevControl = ({ className, onClick }) => (
    <CustomButton
        size='medium'
        color='secondary'
        onClick={onClick}
        className={className}
        aria-label='Prev'
        // style={style}
    >
        <KeyboardArrowLeft />
    </CustomButton>
);
const NextControl = ({ className, onClick }) => (
    <CustomButton
        size='medium'
        color='secondary'
        onClick={onClick}
        className={className}
        aria-label='Next'
        // style={style}
    >
        <KeyboardArrowRight />
    </CustomButton>
);

const settings = {
    dots: true,
    infinite: true,
    fade: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <NextControl />,
    prevArrow: <PrevControl />,
};

const Carousel = ({ items, intl: { formatMessage } }) => {
    return (
        <Slider {...settings} className='slider-main'>
            {items.map((item, index) => {
                const img = {
                    name: item.node.name,
                    dirName: item.node.relativeDirectory,
                    infos: item.node.childImageSharp.fluid,
                };
                const title = `actu.${img.dirName}.title`;
                return (
                    <div key={index} className='slide'>
                        <div className='infos-bloc'>
                            <FormattedMessage id={title} tagName='h3' />
                        </div>
                        <Img
                            fluid={img.infos}
                            style={{
                                height: 'auto',
                                maxHeight: '400px',
                                width: '100%',
                            }}
                            alt={altify(
                                formatMessage({
                                    id: title,
                                }),
                            )}
                            objectFit='cover'
                            objectPosition='50% 50%'
                        />
                    </div>
                );
            })}
        </Slider>
    );
};

Carousel.propTypes = {
    items: PropTypes.array.isRequired,
};

export default injectIntl(Carousel);

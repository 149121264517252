import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { graphql } from 'gatsby';
import ButtonBase from '@material-ui/core/ButtonBase';
import Grid from '@material-ui/core/Grid';

import './Home.styl';

import Parallax from '../views/components/Parallax';
import Landing from '../views/page-blocs/home/landing';
import Slider from '../views/page-blocs/home/slider';
import MainLayout from '../views/layouts/MainLayout';
import SEO from '../views/components/SEO';
import ResponsiveBgImg from '../views/components/ResponsiveBgImg';
import LocalizedLink from '../views/components/LocalizedLink';
import {
    PAGE_CARTERIE,
    PAGE_EMBALLAGE,
    PAGE_OBJET_CADEAU,
} from '../constants/router';

const IndexPage = ({
    pageContext: { locale, originalPath },
    location,
    data,
}) => {
    const actus = data.actu.edges;
    const card = data.card.childImageSharp.fluid;
    const wrap = data.wrap.childImageSharp.fluid;
    const item = data.item.childImageSharp.fluid;

    return (
        <MainLayout
            locale={locale}
            originalPath={originalPath}
            location={location}
        >
            <SEO
                title='pages.home.headerTitle'
                description='pages.home.metaDescription'
                originalPath={originalPath}
                location={location}
                metaIcon={data.seoImage.childImageSharp.resize.src}
                // keywords={[`gatsby`, `application`, `react`]}
            />
            <div className='page-bg homepage'>
                <Parallax
                    img={data.coverImage.childImageSharp.fluid}
                    alt='image-accueil-groupe-editor'
                    blur
                    overlay='extra-light'
                >
                    <div className='page-intro'>
                        <div className='container'>
                            <Landing />
                        </div>
                    </div>
                </Parallax>
                <div className='page-content homepage'>
                    <div className='products-bloc'>
                        <div className='container'>
                            <h3>
                                <FormattedMessage id='pages.home.landing.title' />
                            </h3>

                            <Grid container className='products' spacing={3}>
                                <Grid item xs={12} sm={4}>
                                    <ButtonBase
                                        style={{
                                            width: '100%',
                                            height: '300px',
                                        }}
                                        component={React.forwardRef(
                                            (props, ref) => (
                                                <LocalizedLink
                                                    {...props}
                                                    to={PAGE_CARTERIE}
                                                    innerRef={ref}
                                                />
                                            ),
                                        )}
                                    >
                                        <figure className='product card'>
                                            <ResponsiveBgImg
                                                className='card-bg'
                                                alt='image-carterie-groupe-editor'
                                                {...card}
                                            />
                                            <figcaption>
                                                <div className='title'>
                                                    <FormattedMessage
                                                        tagName='div'
                                                        id='pages.home.landing.card.1'
                                                    />
                                                    {/* <span>
                                                            <FormattedMessage id="pages.home.landing.card.2" />
                                                        </span> */}
                                                </div>
                                            </figcaption>
                                        </figure>
                                        {/* </LocalizedLink> */}
                                    </ButtonBase>
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <ButtonBase
                                        style={{
                                            width: '100%',
                                            height: '300px',
                                        }}
                                        component={React.forwardRef(
                                            (props, ref) => (
                                                <LocalizedLink
                                                    {...props}
                                                    to={PAGE_EMBALLAGE}
                                                    innerRef={ref}
                                                />
                                            ),
                                        )}
                                    >
                                        <figure className='product card'>
                                            <ResponsiveBgImg
                                                className='card-bg'
                                                alt='image-emballage-cadeau-groupe-editor'
                                                {...wrap}
                                            />
                                            <figcaption>
                                                <div className='title'>
                                                    {locale === 'en' ? (
                                                        <>
                                                            <FormattedMessage
                                                                tagName='span'
                                                                id='pages.home.landing.wrap.2'
                                                            />
                                                            <FormattedMessage
                                                                tagName='div'
                                                                id='pages.home.landing.wrap.1'
                                                            />
                                                        </>
                                                    ) : (
                                                        <>
                                                            <FormattedMessage
                                                                tagName='div'
                                                                id='pages.home.landing.wrap.1'
                                                            />
                                                            <FormattedMessage
                                                                tagName='span'
                                                                id='pages.home.landing.wrap.2'
                                                            />
                                                        </>
                                                    )}
                                                </div>
                                            </figcaption>
                                        </figure>
                                    </ButtonBase>
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <ButtonBase
                                        style={{
                                            width: '100%',
                                            height: '300px',
                                        }}
                                        component={React.forwardRef(
                                            (props, ref) => (
                                                <LocalizedLink
                                                    {...props}
                                                    to={PAGE_OBJET_CADEAU}
                                                    innerRef={ref}
                                                />
                                            ),
                                        )}
                                    >
                                        <figure className='product card'>
                                            <ResponsiveBgImg
                                                className='card-bg'
                                                alt='image-objet-cadeau-groupe-editor'
                                                {...item}
                                            />
                                            <figcaption>
                                                <div className='title'>
                                                    {locale === 'en' ? (
                                                        <>
                                                            <FormattedMessage
                                                                tagName='span'
                                                                id='pages.home.landing.item.2'
                                                            />
                                                            <FormattedMessage
                                                                tagName='div'
                                                                id='pages.home.landing.item.1'
                                                            />
                                                        </>
                                                    ) : (
                                                        <>
                                                            <FormattedMessage
                                                                tagName='div'
                                                                id='pages.home.landing.item.1'
                                                            />
                                                            <FormattedMessage
                                                                tagName='span'
                                                                id='pages.home.landing.item.2'
                                                            />
                                                        </>
                                                    )}
                                                </div>
                                            </figcaption>
                                        </figure>
                                    </ButtonBase>
                                </Grid>
                            </Grid>
                        </div>
                    </div>
                </div>
                <Slider items={actus} />
            </div>
        </MainLayout>
    );
};

IndexPage.propTypes = {
    pageContext: PropTypes.shape({
        locale: PropTypes.string.isRequired,
        originalPath: PropTypes.string.isRequired,
    }).isRequired,
    location: PropTypes.object.isRequired,
    data: PropTypes.object.isRequired,
};

export default IndexPage;

export const query = graphql`
    query {
        coverImage: file(
            sourceInstanceName: { eq: "parallax" }
            relativePath: { eq: "accueil-HZ-v2.jpg" }
        ) {
            ...BgImages
        }
        seoImage: file(
            sourceInstanceName: { eq: "parallax" }
            relativePath: { eq: "accueil-HZ-v2.jpg" }
        ) {
            ...SeoImages
        }
        card: file(
            sourceInstanceName: { eq: "images" }
            relativePath: { eq: "lettre-dor-mains.jpg" }
        ) {
            ...CardImages
        }
        wrap: file(
            sourceInstanceName: { eq: "images" }
            relativePath: { eq: "IMG_9587.jpg" }
        ) {
            ...CardImages
        }
        item: file(
            sourceInstanceName: { eq: "images" }
            relativePath: { eq: "IMG_4004-ok.jpg" }
        ) {
            ...CardImages
        }
        actu: allFile(
            filter: { sourceInstanceName: { eq: "actu" } }
            sort: { order: ASC, fields: relativeDirectory }
        ) {
            ...MasonryImages
        }
    }
`;
